import React, { Fragment, useEffect } from "react";

import ShakeIcon from "assets/images/details-pane-icons/shake-sdk-icon.svg";
import CodeIcon from "assets/images/FTUX/add-app-code.svg";
import ListeningIcon from "assets/images/FTUX/add-app-listening.png";

import CodeBlock from "components/CodeBlock/CodeBlock";
import Heading from "components/Heading/Heading";
import PageWrap from "components/PageWrap/PageWrap";
import Paragraph from "components/Paragraph/Paragraph";
import displayToast from "components/Toast/displayToast";
import Toast from "components/Toast/Toast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { PlatformName } from "models";

import { useTeamMembersConsumer } from "pages/Administration/consumers/useTeamMembersConsumer";
import {
  SdkSetupSteps,
  useAndroidSdkSetup,
  useFlutterSdkSetup,
  useIosSdkSetup,
  useReactNativeSdkSetup,
  useWebSdkSetup,
} from "pages/shared/components/SDKSetup/helpers/sdkSetupSteps";
import { SetupStep } from "pages/shared/components/SetupStep/SetupStep";

import { docLink } from "util/docs";

import * as Styled from "./SDKSetupGuide.styles";

const ADD_SDK_TOAST = "add-sdk";

export function SdkSetupSteps() {
  const { selectedApp } = useAppSelectionContext();

  const apiKey = selectedApp?.api_keys[0]?.key ?? "your-api-key";
  const platform = selectedApp?.platform;
  const latestVersion = selectedApp?.platform?.latest_version ?? "";

  const androidSdkSetup = useAndroidSdkSetup(apiKey, latestVersion);
  const iosSdkSetup = useIosSdkSetup(apiKey);
  const reactNativeSdkSetup = useReactNativeSdkSetup(platform, apiKey);
  const flutterSdkSetup = useFlutterSdkSetup(apiKey);
  const webSdkSetup = useWebSdkSetup(apiKey);

  switch (selectedApp?.platform?.name) {
    case PlatformName.ANDROID:
      return <SdkSetupStepsView sdkSetupSteps={androidSdkSetup} />;
    case PlatformName.IOS:
      return <SdkSetupStepsView sdkSetupSteps={iosSdkSetup} />;
    case PlatformName.FLUTTER:
      return <SdkSetupStepsView sdkSetupSteps={flutterSdkSetup} />;
    case PlatformName.REACT_NATIVE:
      return <SdkSetupStepsView sdkSetupSteps={reactNativeSdkSetup} />;
    case PlatformName.WEB:
      return <SdkSetupStepsView sdkSetupSteps={webSdkSetup} />;
    default:
      return null;
  }
}

interface Props {
  sdkSetupSteps: SdkSetupSteps;
}

const SdkSetupStepsView = ({ sdkSetupSteps }: Props) => {
  const { selectedApp } = useAppSelectionContext();
  const appName = selectedApp?.name ?? "your app";

  useEffect(() => {
    displayToast({
      icon: CodeIcon,
      containerId: ADD_SDK_TOAST,
      content: (
        <Fragment>
          If you feel adventurous and want to customize the installation, visit full&nbsp;
          {<a href={docLink(selectedApp?.platform.name).installation}>SDK documentation</a>}.
        </Fragment>
      ),
    });
  }, [appName, selectedApp?.platform.name]);

  return (
    <PageWrap
      key={appName}
      style={{ overflow: "scroll", height: "100%", display: "flex", flex: 1, justifyContent: "center" }}
    >
      <Styled.Container>
        <Heading
          className={"center"}
          heading1
          marginBottom={58}
        >
          {`Add`}&nbsp;
          <span>
            <img
              alt="sdk-icon"
              src={ShakeIcon}
            />
          </span>
          {`SDK to ${appName}`}
        </Heading>
        <Styled.Helper>
          Now listening to {appName} {selectedApp?.platform?.name} app. When you finish the installation, your app will
          ping our servers and you will be automatically taken to the next step.
        </Styled.Helper>
        {sdkSetupSteps.steps.map((step, index) => (
          <div key={step.codeBlock[0].title}>
            <SetupStep
              number={index + 1}
              title={step.title}
            />
            <Styled.StepContent>
              {step.subtitle && (
                <Paragraph
                  lineHeight={26}
                  marginBottom={12}
                >
                  {step.subtitle}
                </Paragraph>
              )}
              <CodeBlock
                tabController={step.controller}
                tabs={step.codeBlock}
              />
              {step.footer && (
                <Paragraph
                  lineHeight={26}
                  marginTop={12}
                  marginBottom={60}
                >
                  {step.footer}
                </Paragraph>
              )}
            </Styled.StepContent>
          </div>
        ))}
        <Toast
          containerId={ADD_SDK_TOAST}
          autoClose={false}
        />
      </Styled.Container>
    </PageWrap>
  );
};
