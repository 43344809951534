import React, { useMemo } from "react";

import { generatePath, matchRoutes, Navigate, useLocation } from "react-router-dom";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";

import { RoutePaths } from "router/config/routePaths";

export default function NotFoundPage() {
  const validAppLink = useOldLinksRedirect();

  if (validAppLink) {
    return (
      <Navigate
        to={validAppLink}
        replace
      />
    );
  }

  return (
    <EmptyStateElement
      title="Page not found"
      subtitle="The link you followed may be broken, or the page may have been removed."
      imageSrc={ErrorImage}
      button={{ url: "/", title: "Return to dashboard" }}
    />
  );
}

enum OLD_RoutePaths {
  WORKSPACE_ROOT = "/:workspaceSlug",
  APP_ROOT = "/:workspaceSlug/:appKey",
  USER_FEEDBACK_DETAILS = "/:workspaceSlug/:appKey/feedback/:userFeedbackKey",
  HOME = "home/*",
  ADMINISTRATION_ROOT = "/administration/apps/*",
}

function useOldLinksRedirect() {
  const location = useLocation();

  const matchData = useMemo(
    () =>
      matchRoutes(
        Object.values(OLD_RoutePaths).map((path) => {
          return { path };
        }),
        location,
      ),
    [location],
  );

  const validAppLink = useMemo(() => {
    if (!matchData) return;

    const dynamicData = matchData[0];

    if (!dynamicData) return;

    if (dynamicData.route.path?.includes(OLD_RoutePaths.USER_FEEDBACK_DETAILS)) {
      return generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
        appKey: dynamicData.params.appKey ?? null,
        workspaceSlug: dynamicData.params.workspaceSlug ?? null,
        userFeedbackKey: dynamicData.params.userFeedbackKey ?? null,
      });
    }

    if (dynamicData.route.path?.includes(OLD_RoutePaths.ADMINISTRATION_ROOT)) {
      return generatePath(RoutePaths.USER_FEEDBACK, {
        workspaceSlug: "administration",
        appKey: getAppRoute(dynamicData.pathname),
      });
    }

    if (dynamicData.route.path?.includes(OLD_RoutePaths.APP_ROOT)) {
      return generatePath(RoutePaths.USER_FEEDBACK, {
        appKey: dynamicData.params.appKey ?? null,
        workspaceSlug: dynamicData.params.workspaceSlug ?? null,
      });
    }

    // this is a hack - it enables redirect from outside of the app to home pages
    if (dynamicData.route.path?.includes(OLD_RoutePaths.HOME)) {
      return generatePath(RoutePaths.USER_FEEDBACK, {
        workspaceSlug: "home",
        appKey: getInfoRoute(dynamicData.pathname),
      });
    }

    if (dynamicData.route.path?.includes(OLD_RoutePaths.WORKSPACE_ROOT)) {
      return generatePath(RoutePaths.USER_FEEDBACK_MODULE_ROOT, {
        workspaceSlug: dynamicData.params.workspaceSlug ?? null,
      });
    }
  }, [matchData]);

  return validAppLink;
}

const getInfoRoute = (path: string) => {
  if (path.includes("/customer-support")) return "customer-support";
  if (path.includes("/product-managers")) return "product-managers";
  if (path.includes("/app-developers")) return "app-developers";
  if (path.includes("/app-testers")) return "app-testers";
  if (path.includes("/latest")) return "features/latest";
  if (path.includes("/popular")) return "features/popular";

  return "all";
};

const getAppRoute = (path: string) => {
  if (path.includes("crashes")) return "apps-crashes";
  if (path.includes("users")) return "apps-users";
  if (path.includes("feedback")) return "apps-feedback";
  return "apps-general";
};
