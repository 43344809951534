import { RoutePaths } from "router/config/routePaths";

enum Module {
  feedback = "user-feedback",
  crash = "crash-reports",
  users = "users",
  info = "info",
  stats = "stats",
  addApp = "addApp",
}

export const getModuleRootRouteFromPath = (pathname: string) => {
  const module = pathname.split("/").find((path) => Object.values(Module).includes(path as Module));
  switch (module) {
    case Module.feedback:
      return RoutePaths.USER_FEEDBACK_MODULE_ROOT;
    case Module.crash:
      return RoutePaths.CRASH_MODULE_ROOT;
    case Module.users:
      return RoutePaths.USERS_MODULE_ROOT;
    case Module.stats:
      return RoutePaths.STATS_MODULE_ROOT;
    case Module.addApp:
      return RoutePaths.ADD_APP;
    default:
      return RoutePaths.USER_FEEDBACK_MODULE_ROOT;
  }
};

export const getRouteFromPath = (pathname: string) => {
  const module = pathname.split("/").find((path) => Object.values(Module).includes(path as Module));
  switch (module) {
    case Module.feedback:
      return RoutePaths.USER_FEEDBACK;
    case Module.crash:
      return RoutePaths.CRASH_REPORTS;
    case Module.users:
      return RoutePaths.USERS;
    case Module.stats:
      return RoutePaths.STATS;
    case Module.addApp:
      return RoutePaths.ADD_APP;
    default:
      return RoutePaths.USER_FEEDBACK_MODULE_ROOT;
  }
};

export function isModuleRoute(pathname: string) {
  return pathname.split("/").find((path) => Object.values(Module).includes(path as Module)) != undefined;
}

export function isAdministrationRouteLocation(pathname: string) {
  return pathname.includes(RoutePaths.ADMINISTRATION_ROOT.split("/").pop() ?? "");
}

export function isAccountRouteLocation(pathname: string) {
  return pathname.includes(RoutePaths.ACCOUNT_ROOT.split("/").pop() ?? "");
}

export function isInfoRouteLocation(pathname: string) {
  return pathname.includes(RoutePaths.INFO_ROOT.split("/").pop() ?? "");
}
