import styled, { css } from "styled-components";

import DefaultBg from "assets/images/avatar.svg";

import { vars } from "styles";

import { ReactComponent as ArrowIcon } from "../../assets/images/sidebar-icons/caret-down-icon-lighter.svg";

export const RoundedCanvasWrap = styled.div<{
  $size?: "small" | "normal" | "large" | "xsmall" | "xxsmall";
  $icon?: string;
  $image?: string;
  $letter?: string;
  $fgColor?: string;
  $bgColor?: string;
  $imageSize?: number;
  $txtColor?: string;
  $square?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${(props) => props.$bgColor || vars.colors.grey100};
  background-image: url(${DefaultBg});
  background-repeat: no-repeat;
  background-position: center;
  color: ${(props) => props.$fgColor || vars.colors.white};
  font-weight: 600;

  img {
    object-fit: cover;
    object-position: center;
  }

  ${(props) =>
    props.$square &&
    css`
      border-radius: 0.8rem;
    `}

  ${(props) =>
    props.$bgColor &&
    css`
      background-color: ${props.$bgColor};
    `}

  ${(props) =>
    props.$txtColor &&
    css`
      color: ${props.$txtColor};
    `}

  ${(props) =>
    props.$fgColor &&
    css`
      color: ${props.$fgColor};
    `}

  ${(props) =>
    props.$letter &&
    css`
      background-image: none;
    `}

  ${(props) =>
    props.$size == "xsmall" &&
    css`
      font-weight: 500;
      font-size: 1rem;
      width: 2.6rem;
      height: 2.6rem;
      background-size: 1.2rem;

      > svg {
        width: 1.6rem !important;
        height: 1.6rem !important;
        margin-right: 0rem !important;
      }

      img {
        width: 2.6rem;
        height: 2.6rem;
      }
    `}

  ${(props) =>
    props.$size == "xxsmall" &&
    css`
      font-weight: 500;
      font-size: 1rem;
      width: 2.4rem;
      height: 2.4rem;
      background-size: 1.2rem;

      > svg {
        width: 1.6rem !important;
        height: 1.6rem !important;
        margin-right: 0rem !important;
      }

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    `}

  ${(props) =>
    props.$size == "small" &&
    css`
      font-size: 1.2rem;
      width: 3.2rem;
      height: 3.2rem;
      background-size: 1.2rem;

      > svg {
        width: 1.6rem !important;
        height: 1.6rem !important;
        margin-right: 0rem !important;
      }

      img {
        width: 3.2rem;
        height: 3.2rem;
      }
    `}

  
  ${(props) =>
    props.$size == "xsmall" &&
    css`
      font-size: 1rem;
      width: 2.4rem;
      height: 2.4rem;
      background-size: 1.2rem;

      > svg {
        width: 1.6rem !important;
        height: 1.6rem !important;
        margin-right: 0rem !important;
      }

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    `}

  ${(props) =>
    props.$size == "normal" &&
    css`
      font-size: 1.4rem;
      width: 4rem;
      height: 4rem;
      background-size: 1.4rem;

      > svg {
        width: 1.8rem !important;
        height: 1.8rem !important;
        margin-right: 0rem !important;
      }

      img {
        width: 4rem;
        height: 4rem;
      }
    `}
  ${(props) =>
    props.$size == "large" &&
    css`
      font-size: 2.4rem;
      width: 6rem;
      height: 6rem;
      background-size: 2.4rem;

      > svg {
        width: 2.8rem !important;
        height: 2.8rem !important;
        margin-right: 0rem !important;
      }

      img {
        width: 6rem !important;
        height: 6rem !important;
      }
    `}
  ${(props) =>
    props.$image &&
    css`
      background-image: none;

      .letter {
        display: none;
      }
    `}
  ${(props) =>
    props.$icon &&
    css`
      background-image: none;
      margin-right: 0;
      .letter {
        display: none;
      }
    `}

    ${({ $imageSize }) =>
    $imageSize &&
    css`
      img {
        width: ${$imageSize}rem;
        height: ${$imageSize}rem;
      }
    `}
`;

export const Arrow = styled(ArrowIcon)<{ $direction: "down" | "right" }>`
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  right: -8%;
  transform: translate(-50%, -50%) ${({ $direction }) => ($direction === "right" ? "rotate(-90deg)" : "rotate(0deg)")};
  background-color: ${vars.colors.grey100};
  border-radius: 50%;
  transition: transform 0.3s ease-in-out; /* Smooth rotation animation */
`;
