import React from "react";

import { Spinner } from "components/Spinner/Spinner";

import { useAppContext } from "context/App/App.context";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import ChangeLog from "../../components/ChangeLog/ChangeLog";
import useFeaturesConsumer from "../../consumers/useFeaturesConsumer";

export default function FeaturesLatest() {
  const { latestChangeLogs, latestLoading, onMarkAsReadRequest } = useFeaturesConsumer({ latest: true });
  const { unreadChangeLogNotifications } = useAppContext();

  if (latestLoading || !latestChangeLogs) return <Spinner />;

  return (
    <div style={{ justifyContent: "flex-start", marginTop: "2.8rem", maxWidth: "88rem", width: "100%" }}>
      <Flex
        $column
        $gap={4}
        style={{ marginTop: "4rem" }}
      >
        {latestChangeLogs?.map((log, index) => {
          return (
            <ChangeLog
              key={index}
              changeLog={log}
              isUnread={Boolean(unreadChangeLogNotifications.find((no) => no.changelog_id === log.id))}
              markAsRead={onMarkAsReadRequest}
            />
          );
        })}
      </Flex>
    </div>
  );
}
