import React from "react";

import VideosSection from "../../components/VideoSection/VideoSection";
import { all, customerSupportVideos, developerVideos, productManagersVideos, testerVideos } from "../../helpers/videos";

export default function HowToAll() {
  const allVideos = all
    .concat(productManagersVideos)
    .concat(developerVideos)
    .concat(testerVideos)
    .concat(customerSupportVideos)
    .filter((value, index, self) => index === self.findIndex((t) => t.title === value.title));

  return <VideosSection videos={allVideos} />;
}
