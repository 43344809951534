import React, { Fragment, useEffect } from "react";

import { generatePath, useNavigate } from "react-router-dom";

import webImage from "assets/images/empty-state/click-the-floating-button.png";
import mobileImage from "assets/images/empty-state/deep-purple.png";
import background from "assets/images/empty-state/feedback-module-empty-heading.png";
import HouseIcon from "assets/images/FTUX/house-icon.svg";

import Paragraph from "components/Paragraph/Paragraph";
import displayToast from "components/Toast/displayToast";

import { FeedbackModuleDescriptionBox } from "pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";

import * as Styled from "./ModuleEmptyState.styles";
import Toast from "../../../../components/Toast/Toast";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import { App, PlatformName, Workspace } from "../../../../models";
import { RoutePaths } from "../../../../router/config/routePaths";
import { vars } from "../../../../styles";
import { docLink } from "../../../../util/docs";
import { SetupStep } from "../SetupStep/SetupStep";

const FEEDBACK_SOMETHING_NOT_WORKING_TOAST = "feedback-something-not-working";

export const FeedbackModuleEmptyState = () => {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedWorkspace) return;
    displayToast({
      icon: HouseIcon,
      containerId: FEEDBACK_SOMETHING_NOT_WORKING_TOAST,
      title: "Something not working?",
      content: (
        <Fragment>
          Whenever you need help, visit{" "}
          {
            <Styled.StyledLink
              onClick={() => navigate(generatePath(RoutePaths.INFO_ROOT, { workspaceSlug: selectedWorkspace.slug }))}
            >
              Info
            </Styled.StyledLink>
          }{" "}
          where you will find links to help center, our Slack community and to private chat with our support engineers.
        </Fragment>
      ),
    });
  }, [navigate, selectedWorkspace]);

  const isWeb = selectedApp?.platform.name === PlatformName.WEB;

  const Description = () => {
    return isWeb ? (
      <Paragraph
        lineHeight={18}
        marginBottom={10}
      >
        Shake is live in your {selectedApp.name} website. Visit it<Styled.StepReference>1</Styled.StepReference> and
        click the new floating button<Styled.StepReference>2</Styled.StepReference> to report the first ticket.
      </Paragraph>
    ) : (
      <Paragraph
        lineHeight={18}
        marginBottom={10}
      >
        Shake is alive in your {selectedApp?.name} {selectedApp?.platform.name} app. Open the app
        <Styled.StepReference>1</Styled.StepReference> and shake the device
        <Styled.StepReference>2</Styled.StepReference> to report the first ticket.
      </Paragraph>
    );
  };

  const Steps = () => {
    return isWeb ? (
      <Styled.Steps>
        <Paragraph
          lineHeight={18}
          marginBottom={1}
          color={vars.colors.grey70}
          fontSize={14}
        >
          <Styled.StepReference>1</Styled.StepReference> Make sure your website version contains the Shake SDK.
        </Paragraph>
        <Paragraph
          lineHeight={18}
          marginBottom={10}
          color={vars.colors.grey70}
          fontSize={14}
        >
          <Styled.StepReference>2</Styled.StepReference> Or{" "}
          <Styled.Link
            target="_blank"
            rel="noreferrer"
            href={docLink(selectedApp.platform.name).invoke}
          >
            any other invocation
          </Styled.Link>{" "}
          method you have chosen.
        </Paragraph>
        <Styled.Image src={webImage} />
      </Styled.Steps>
    ) : (
      <Styled.Steps>
        <Paragraph
          lineHeight={18}
          marginBottom={1}
          color={vars.colors.grey70}
          fontSize={14}
        >
          <Styled.StepReference>1</Styled.StepReference> Make sure you have an app build with Shake SDK inside.
        </Paragraph>
        <Paragraph
          lineHeight={18}
          marginBottom={10}
          color={vars.colors.grey70}
          fontSize={14}
        >
          <Styled.StepReference>2</Styled.StepReference> Or{" "}
          <Styled.Link
            target="_blank"
            rel="noreferrer"
            href={docLink(selectedApp?.platform.name).invoke}
          >
            any other invocation
          </Styled.Link>{" "}
          method you have chosen.
        </Paragraph>
        <Styled.Image src={mobileImage} />
      </Styled.Steps>
    );
  };

  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <FeedbackModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={isWeb ? "Click the floating button" : "Shake your device"}
        />
        <Styled.StepContent>
          <Description />
          <Steps />
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={FEEDBACK_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};
