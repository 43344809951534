import React from "react";

import * as Styled from "./RoundedCanvas.styles";
import Icon from "../Icon/Icon";

export interface RoundedCanvasProps {
  size?: "small" | "normal" | "large" | "xsmall" | "xxsmall";
  icon?: string;
  image?: string;
  letter?: string;
  fgColor?: string;
  txtColor?: string;
  bgColor?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  imageSize?: number;
  testId?: string;
  square?: boolean;
  arrow?: "down" | "right";
}

const RoundedCanvas: React.FC<RoundedCanvasProps> = ({
  size = "normal",
  icon,
  image,
  letter,
  fgColor,
  txtColor,
  bgColor,
  onClick,
  onMouseOver,
  imageSize,
  testId,
  square,
  arrow,
}) => {
  return (
    <div
      onClick={onClick}
      onMouseOver={onMouseOver}
      data-testid={testId}
    >
      <Styled.RoundedCanvasWrap
        $size={size}
        $icon={icon}
        $image={image}
        $letter={letter}
        $fgColor={fgColor}
        $bgColor={image ? "none" : bgColor}
        $txtColor={txtColor}
        $imageSize={imageSize}
        $square={square}
      >
        {image && (
          <img
            alt="avatar"
            src={image}
          />
        )}
        {icon && (
          <Icon
            icon={icon}
            color={fgColor}
          />
        )}
        {letter && <span className="letter">{letter}</span>}
      </Styled.RoundedCanvasWrap>
      {arrow && <Styled.Arrow $direction={arrow} />}
    </div>
  );
};

export default RoundedCanvas;
