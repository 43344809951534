import { useCallback, useEffect, useRef, useState } from "react";

import { useAuthDeps } from "App.dependencies";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import { setUserTokenAction } from "context/Auth/Auth.actions";
import { useAuthDispatch } from "context/Auth/Auth.context";
import { USER_TOKEN_LOCAL_STORAGE_KEY } from "context/Auth/Auth.types";

import { UserToken } from "models";
import { TeamInfo, TeamInvitationRes } from "models/TeamInvitation.model";

import { parseGoogleCallback } from "pages/SignIn/utils/parseGoogleCallback";
import { useGoogleSignIn } from "pages/SignIn/utils/useGoogleSignIn";
import { useWorkspaceJoinInvitationDeps } from "pages/WorkspaceJoinInvitation/WorkspaceJoinInvitation";

import { RoutePaths } from "router/config/routePaths";

enum ErrorStatusCodes {
  EXPIRED_CODE = 403,
}

interface GoogleJoinPassthroughState {
  teamInfo: TeamInfo;
  invitationCode: string;
}

export const useWorkspaceJoinInvitationViewApiConsumer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { authService } = useAuthDeps();
  const authDispatch = useAuthDispatch();

  const [code, setCode] = useState(searchParams.get("auth_callback") === null ? searchParams.get("code") : null);
  const { invitationService } = useWorkspaceJoinInvitationDeps();

  const currentURL = new URL(window.location.href);
  const { redirectToGoogle } = useGoogleSignIn({ currentURL });
  const { code: googleCode, error: googleError, restoreOriginalURL, redirectURL } = parseGoogleCallback({ currentURL });

  const [teamInfo, setTeamInfo] = useState<TeamInfo>();
  const [expiredInfo, setExpiredInfo] = useState<TeamInfo>();
  const [googleInvalidEmailJoin, setGoogleInvalidEmailJoin] = useState(false);

  const [validating, setValidating] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleUserToken = useCallback(
    async (userToken: UserToken) => {
      localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(userToken));
      authDispatch(setUserTokenAction(userToken));
    },
    [authDispatch],
  );

  const checkInvitationCode = useCallback(
    async (code: string | null) => {
      try {
        if (!code) return;

        // This request performs user registration also.
        const teamInviteRes = await invitationService.checkInvitationCode(code);

        if (teamInviteRes.token) {
          await handleUserToken(teamInviteRes.token);
          navigate(
            generatePath(RoutePaths.USER_FEEDBACK_MODULE_ROOT, { workspaceSlug: teamInviteRes.team_info.team_slug }),
          );

          !teamInviteRes.team_info.member_in_workspace &&
            displayToast({
              title: "Fantastic!",
              content: `You successfully joined ${teamInviteRes.team_info.team_name} workspace.`,
            });

          return;
        }

        setTeamInfo(teamInviteRes.team_info);
      } catch (error) {
        if (error.response.status === ErrorStatusCodes.EXPIRED_CODE) {
          const expiredData = JSON.parse(error.response.data.message) as TeamInvitationRes;
          setExpiredInfo(expiredData.team_info);
        }
      } finally {
        setValidating(false);
      }
    },
    [handleUserToken, invitationService, navigate],
  );

  const joinWithPassword = async (password: string) => {
    try {
      if (!code || !teamInfo) throw new Error();

      setLoading(true);

      const { data: activationRes } = await invitationService.inviteActivation(code, password);

      const { data: userToken } = await authService.signIn(activationRes.email, password);

      await handleUserToken(userToken);

      navigate(generatePath(RoutePaths.USER_FEEDBACK_MODULE_ROOT, { workspaceSlug: teamInfo.team_slug }));
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    } finally {
      setLoading(false);
    }
  };

  const joinWithGoogleCodeRef = useRef(async () => {
    try {
      setLoading(true);
      setValidating(false);

      if (googleError) throw new Error(googleError);
      if (!googleCode || !redirectURL) return;

      const stateParam = searchParams.get("state") || "{}";
      const passthroughState = JSON.parse(stateParam).clientState as GoogleJoinPassthroughState;

      setTeamInfo(passthroughState.teamInfo);
      setCode(passthroughState.invitationCode);

      const { data: userToken } = await authService.confirmInviteWithGoogle(
        passthroughState.invitationCode,
        googleCode,
        redirectURL.href,
      );

      await handleUserToken(userToken);

      navigate(
        generatePath(RoutePaths.USER_FEEDBACK_MODULE_ROOT, { workspaceSlug: passthroughState.teamInfo.team_slug }),
      );
    } catch (error) {
      restoreOriginalURL?.();
      if (error.response.status === 403) {
        setGoogleInvalidEmailJoin(true);
        return;
      }
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    checkInvitationCode(code);
  }, [checkInvitationCode, code]);

  useEffect(() => {
    googleCode && joinWithGoogleCodeRef.current();
  }, [googleCode]);

  return {
    teamInfo,
    joinWithPassword,
    validating,
    expiredInfo,
    loading,
    redirectToGoogle: () => {
      if (!teamInfo || !code) return;
      setLoading(true);
      const clientState: GoogleJoinPassthroughState = {
        teamInfo,
        invitationCode: code,
      };
      redirectToGoogle({ clientState });
    },
    googleInvalidEmailJoin,
  };
};
