import React, { Fragment, useEffect } from "react";

import { generatePath, useNavigate } from "react-router-dom";

import background from "assets/images/empty-state/users-module-empty-heading.png";

import CodeBlock, { Tab } from "components/CodeBlock/CodeBlock";
import {
  TabController,
  useAndroidTabController,
  useFlutterTabController,
  useIosTabController,
  useReactNativeTabController,
  useWebTabController,
} from "components/CodeBlock/useCodeTabsController";
import Paragraph from "components/Paragraph/Paragraph";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { PlatformName } from "models";

import { UsersModuleDescriptionBox } from "pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";
import {
  androidUsersSetupSteps,
  flutterUsersSetupSteps,
  iosUsersSetupSteps,
  rnUsersSetupSteps,
  webUsersSetupSteps,
} from "pages/shared/components/ModuleEmptyState/helpers/usersSetupSteps";

import * as Styled from "./ModuleEmptyState.styles";
import HouseIcon from "../../../../assets/images/FTUX/house-icon.svg";
import displayToast from "../../../../components/Toast/displayToast";
import Toast from "../../../../components/Toast/Toast";
import { RoutePaths } from "../../../../router/config/routePaths";
import { SetupStep } from "../SetupStep/SetupStep";

const USERS_SOMETHING_NOT_WORKING_TOAST = "users-something-not-working";

export const UsersModuleEmptyState = () => {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedWorkspace) return;
    displayToast({
      icon: HouseIcon,
      containerId: USERS_SOMETHING_NOT_WORKING_TOAST,
      title: "Something not working?",
      content: (
        <Fragment>
          Whenever you need help, visit{" "}
          {
            <Styled.StyledLink
              onClick={() => navigate(generatePath(RoutePaths.INFO_ROOT, { workspaceSlug: selectedWorkspace.slug }))}
            >
              Info
            </Styled.StyledLink>
          }{" "}
          where you will find links to help center, our Slack community and to private chat with our support engineers.
        </Fragment>
      ),
    });
  }, [navigate, selectedWorkspace]);

  const androidTabController = useAndroidTabController();
  const iosTabController = useIosTabController();
  const reactNativeTabController = useReactNativeTabController();
  const flutterTabController = useFlutterTabController();
  const webTabController = useWebTabController();

  switch (selectedApp?.platform?.name) {
    case PlatformName.ANDROID:
      return (
        <UserModuleEmptyView
          codeBlockTabs={androidUsersSetupSteps.registerUserCode}
          tabController={androidTabController}
        />
      );
    case PlatformName.IOS:
      return (
        <UserModuleEmptyView
          codeBlockTabs={iosUsersSetupSteps.registerUserCode}
          tabController={iosTabController}
        />
      );
    case PlatformName.REACT_NATIVE:
      return (
        <UserModuleEmptyView
          codeBlockTabs={rnUsersSetupSteps.registerUserCode}
          tabController={reactNativeTabController}
        />
      );
    case PlatformName.FLUTTER:
      return (
        <UserModuleEmptyView
          codeBlockTabs={flutterUsersSetupSteps.registerUserCode}
          tabController={flutterTabController}
        />
      );
    case PlatformName.WEB:
      return (
        <UserModuleEmptyView
          codeBlockTabs={webUsersSetupSteps.registerUserCode}
          tabController={webTabController}
        />
      );
    default:
      return null;
  }
};

export interface Props {
  codeBlockTabs: Tab[];
  tabController: TabController;
}

const UserModuleEmptyView = ({ codeBlockTabs, tabController }: Props) => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <UsersModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Register app users"}
        />
        <Styled.StepContent>
          <CodeBlock
            tabController={tabController}
            tabs={codeBlockTabs}
          />
          <Paragraph
            lineHeight={26}
            marginTop={12}
            marginBottom={60}
          >
            {"And that’s it — your app users will start appearing here as soon as they sign into your app."}
          </Paragraph>
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={USERS_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};
